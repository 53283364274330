<template>
  <div class="page">
    <div class="container">
      <div class="header">
        <div class="icon"></div>
        <!-- <div class="title">{{ $t('userDetailPage.title') }}</div> -->
        <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">{{ $t('userPage.title') }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ $t('userDetailPage.title') }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="content">
        <div class="subtitle">
          <!-- <div class="icon"></div> -->
          <div class="title">用户信息</div>
        </div>
        <div class="host-info">
          <el-descriptions
            :column="2"
            border
          >
            <el-descriptions-item>
              <template #label>
                <i class="el-icon-user"></i>
                用户名
              </template>
              kooriookami
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <i class="el-icon-mobile-phone"></i>
                手机号
              </template>
              18100000000
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <i class="el-icon-location-outline"></i>
                居住地
              </template>
              苏州市
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <i class="el-icon-tickets"></i>
                备注
              </template>
              <el-tag size="small">学校</el-tag>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <i class="el-icon-office-building"></i>
                联系地址
              </template>
              江苏省苏州市吴中区吴中大道 1188 号
            </el-descriptions-item>
          </el-descriptions>
        </div>

        <div class="subtitle">
          <!-- <div class="icon"></div> -->
          <div class="title">会议列表</div>
        </div>
        <div>
          <el-table
            class="table"
            :data="tableData"
            header-cell-class-name="header"
            style="width: 100%"
          >
            <el-table-column prop="date" label="序号" align="center" width="60">
              <template v-slot="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column prop="date" label="日期" align="center">
            </el-table-column>
            <el-table-column prop="name" label="姓名" align="center">
            </el-table-column>
            <el-table-column prop="address" label="地址" align="center">
            </el-table-column>
            <el-table-column
              label="操作"
              width="160"
              align="center"
            >
              <template v-slot="scope">
                <el-button type="primary" link @click="handleDetail(scope.row)">详情</el-button>
                <el-button type="primary" link>编辑</el-button>
                <el-button type="danger" link @click="handleDel(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <common-page
            v-model:current-page="pageObj.page"
            v-model:page-size="pageObj.size"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </common-page>
        </div>
      </div>
    </div>
    <!-- 创建和修改弹窗 -->
    <el-dialog
      ref="createDialog"
      class="create-dialog"
      :show-close="false"
      :title="createDialog.title"
      :close-on-click-modal = false
      v-model="createDialog.show"
      width="40vw"
      center
    >
      <div class="main">
        <el-form
          ref="createForm"
          label-position="right"
          label-width="120px"
          :model="createForm"
          :rules="createRules"
          style="width:100%"
        >
          <el-form-item prop="name" label="姓名：">
            <el-input v-model="createForm.name"></el-input>
          </el-form-item>
          <el-form-item prop="phone" label="手机号码：">
            <el-input v-model="createForm.phone"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancelCreate">取 消</el-button>
          <el-button type="primary" @click="submitCreateForm">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import commonPage from '@/components/public/pagination.vue'
export default {
  components: {
    commonPage
  },
  data () {
    return {
      userList: [],
      tableData: [
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }
      ],
      pageObj: {
        page: 1,
        size: 10
      },
      createDialog: {
        title: '创建主持人',
        show: false
      },
      createForm: {
        name: '',
        phone: ''
      },
      createRules: {
        name: [
          { required: true, message: '必填项不能为空', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '必填项不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    handleCurrentChange (val) {
      console.log(val)
    },
    handleSizeChange (val) {
      this.pageObj.page = 1
      console.log(val)
    },
    handleCreate () {
      this.createDialog.title = '创建主持人'
      this.createDialog.show = true
    },
    resetDialog () {
      this.createDialog = {
        title: '创建主持人',
        show: false
      }
    },
    submitCreateForm () {
      this.createForm.show = false
    },
    cancelCreate () {
      this.$refs.createForm.resetFields()
      this.createDialog.show = false
    },
    handleDetail (item) {
      this.$router.push('/hostDetail')
    },
    handleDel (item) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
        })
        .catch(() => {
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  margin: 0 auto;
  width: 1200px;
  background: white;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  padding: 20px;
  margin-top: 20px;
}
.header {
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 20px;
  .icon {
    width: 6px;
    height: 30px;
    background: $colorGreen;
    margin-right: 10px;
  }
  .title {
    line-height: 40px;
    font-size: 18px;
    font-weight: bold;
  }
  .search {
    margin-left: auto;
  }
}
.subtitle {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  color: $subTitleColor;
  .icon {
    width: 4px;
    height: 24px;
    background: $subTitleColor;
    margin-right: 10px;
  }
  .title {
    font-size: 16px;
    font-weight: bold;
  }
}
.host-info {
  margin-bottom: 40px;
}
.create-dialog {
  .main {
    display: flex;
    justify-content: center;
  }
}
</style>
